<template>
  <div>
    <div class="shopify-section">
      <div class="section-header flex-bet-cen">
        <div class="section-title font-medium">
          {{ $trans('艺术家', '艺术家') }}
        </div>
      </div>
      <div
        v-if="blogsList.length < 1"
        @click="getArtistPageList"
        class="empty-box"
      >
        <el-empty
          image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
        ></el-empty>
      </div>
      <div
        v-else
        v-infinite-scroll="getArtistPageList"
        infinite-scroll-delay="100"
        infinite-scroll-distance="600"
      >
        <Blogs :data="blogsList" />
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import { loadLocaleMessages } from '@/i18n/i18n'
import Blogs from '@/components/three/Blogs.vue'
import Footer from '@/components/layout/Footer.vue'
import { getArtistPageList } from '@/util/js/api.js'
export default {
  name: '',
  data() {
    return {
      blogsList: [], // 博客数据
      isGetBlogsData: false, // 是否处于获取数据状态
      currentPage: 0, // 当前页码
      isGetAllBlogs: false, // 是否请求完全部数据
      total: 0, // 全部数据
    }
  },
  components: {
    Blogs,
    Footer,
  },
  mounted() {
    this.getArtistPageList()
  },
  methods: {
    getArtistPageList() {
      // debugger
      if (this.isGetBlogsData || this.isGetAllBlogs) {
        return false
      } else {
        this.isGetBlogsData = true
        this.currentPage++
        let params = {
          age: 0,
          avatar: '',
          brief: '',
          content: '',
          currentPage: this.currentPage,
          delFlag: '',
          goodsBrief: '',
          id: 0,
          name: '',
          pageSize: 10,
          phone: '',
          profileImg1: '',
          profileImg2: '',
          profileImg3: '',
          sex: '',
          weigh: 0,
        }
        getArtistPageList(params)
          .then(
            (res) => {
              this.isGetBlogsData = false
              let arr = JSON.parse(JSON.stringify(res.data.rows))
              // let newArr = arr.map((item) => {
              //   if (item.content) {
              //     let arr = item.content.split('<sign>')
              //     item.title = arr[0]
              //   }
              //   return item
              // })
              arr.forEach((item) => {
                if (item.transferList) {
                  loadLocaleMessages(item.transferList, `artist-${item.id}`)
                }
              })
              this.total = Number(res.data.total)
              this.blogsList = [...this.blogsList, ...arr]
              if (this.blogsList.length >= this.total) {
                this.isGetAllBlogs = true
              }
            },
            () => {
              this.isGetBlogsData = false
              this.$message.error(
                this.$trans('请求超时，请稍后重试', '请求超时，请稍后重试')
              )
            }
          )
          .catch(() => {})
      }
    },
  },
}
</script>
<style scoped>
.shopify-section {
  margin: 80px 60px;
  max-width: 1700px;
}
.section-header {
  margin-bottom: 30px;
}
.section-title {
  color: #000000;
  font-size: 24px;
}
</style>
